import React from 'react'
import Accordion from '../../Components/Accordion/Accordion';

const Terms = () => {
  const header = {
    title: 'Terms of Use',
    subTitle: "If you use Chefsknock, you agree to these."
  }
  const terms = [
    {
      question: "Introduction",
      answer:
        "When you use Chefsknock, you're agreeing to all the rules on this page. Some of them need to be expressed in legal language, but we've done our best to offer you clear and simple explanations of what everything means. Chefsknock is a service offered by Chefsknock Pte. Ltd. These terms and conditions are governed by and will be construed in accordance with the laws of Singapore.",
    },
    {
      question: "Creating an Account",
      answer:
        "To sign up for a Chefsknock account, you need to be 18 or over. You're responsible for your account and all the activity on it. You can browse Chefsknock without registering for an account. But to use some of our features, you'll need to register, choose a username, and set a password. When you do that, the information you give us has to be accurate and complete. Don't impersonate anyone else or choose names that are offensive or that violate anyone's rights. If you don't follow these rules, we may cancel your account. You're responsible for all the activity on your account, and for keeping your password confidential. If you find out that someone has used your account without your permission, you should report it to hello@chefsknock.com. To sign up for an account, you need to be at least 18 years old, or old enough to form a binding contract where you live. If necessary, we may ask you for proof of age.",
    },
    {
      question: "Things that are big no-no's",
      answer:
        "Thousands of people use Chefsknock. We expect all of you to behave responsibly and help keep this a nice place. If you want be a part of this don't do any of these things on our site:\n\nDon't break the law. Don't take any action that infringes or violates other people's rights, violates the law, or breaches any contract or legal duty you have toward anyone.\n\nDon't lie to people. Don't post information you know is false, misleading, or inaccurate. Don't do anything deceptive or fraudulent.\n\nDon't offer prohibited items. Don't offer any rewards that are illegal, violate any of Chefsknock's policies, rules, or guidelines, or violate any applicable law, statute, ordinance, or regulation. See our Acceptable Use Policy.\n\nDon't victimize anyone. Don't do anything threatening, abusive, harassing, defamatory, libelous, tortious, obscene, profane, or invasive of another person's privacy.\n\nDon't spam. Don't distribute unsolicited or unauthorized advertising or promotional material, or any junk mail, spam, or chain letters. Don't run mail lists, listservs, or any kind of auto-responder or spam on or through the Site.\n\nDon't harm anyone's computer. Don't distribute software viruses, or anything else designed to interfere with the proper function of any software, hardware, or equipment on the Site (whether it belongs to Chefsknock or another party).\n\nDon't abuse other users' personal information. When you use Chefsknock — and especially if you create a successful page — you may receive information about other users, including things like their names, email addresses, and postal addresses. This information is provided for the purpose of participating in Chefsknock: don't use it for other purposes, and don't abuse it.",
    },
    {
      question: "Things we don't do and are not responsible for",
      answer:
        "Chefsknock is a platform that allows people to connect, collaborate, and share information. We provide the platform for users to create and manage content, but we don't endorse or verify the content posted by users. We don't guarantee the accuracy, completeness, or usefulness of any information on Chefsknock, and we don't endorse any opinions expressed by users. You may come across content that is inaccurate, offensive, or objectionable. You use Chefsknock at your own risk. We are not responsible for any harm or damage resulting from your use of Chefsknock or the content posted by users. We are also not responsible for any transactions or interactions that occur between users. If you have a dispute with another user, you should resolve it directly with that user. We are not responsible for any loss or damage incurred as a result of such disputes.",
    },
    {
      question: "Termination",
      answer:
        "We may terminate or suspend your access to Chefsknock at any time, with or without cause, and without prior notice. If we terminate your access, your right to use Chefsknock will immediately cease. All provisions of these terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.",
    },
    {
      question: "Changes to Terms",
      answer:
        "We may modify these terms and conditions at any time, so you should review them periodically. Your continued use of Chefsknock after we post modifications will constitute your acknowledgment of the modified terms and your agreement to abide and be bound by the modified terms. If you do not agree to the modified terms, you should discontinue your use of Chefsknock.",
    },
    {
      question: "Contact Information",
      answer:
        "If you have any questions, concerns, or feedback regarding these terms and conditions, please contact us at contact@chefsknock.com.",
    },
  ];

  return (
    <Accordion header={header} data={terms} />
  )
}

export default Terms
