import './WhyUs.css';
import FooterCard from '../../Components/Card/FooterCard'

const WhyUs = () => {
  return (
    <>
        <div className="section wf-section">
            <div className="blog-post-container w-container">
            <h1 className="blog-post-title"> What&#x27;s New at Chefsknock or Why Choose Us?</h1>
            <img
                src="https://uploads-ssl.webflow.com/60f35132d5febb6352ec9d81/62e15badd261e9145b255187_What%27s%20New%20July.png"
                loading="lazy" alt="" sizes="(max-width: 991px) 90vw, 770px" srcet="
                    https://uploads-ssl.webflow.com/60f35132d5febb6352ec9d81/62e15badd261e9145b255187_What%27s%20New%20July-p-500.png   500w,
                    https://uploads-ssl.webflow.com/60f35132d5febb6352ec9d81/62e15badd261e9145b255187_What%27s%20New%20July-p-800.png   800w,
                    https://uploads-ssl.webflow.com/60f35132d5febb6352ec9d81/62e15badd261e9145b255187_What%27s%20New%20July-p-1080.png 1080w,
                    https://uploads-ssl.webflow.com/60f35132d5febb6352ec9d81/62e15badd261e9145b255187_What%27s%20New%20July-p-1600.png 1600w,
                    https://uploads-ssl.webflow.com/60f35132d5febb6352ec9d81/62e15badd261e9145b255187_What%27s%20New%20July-p-2000.png 2000w,
                    https://uploads-ssl.webflow.com/60f35132d5febb6352ec9d81/62e15badd261e9145b255187_What%27s%20New%20July-p-2600.png 2600w,
                    https://uploads-ssl.webflow.com/60f35132d5febb6352ec9d81/62e15badd261e9145b255187_What%27s%20New%20July-p-3200.png 3200w,
                    https://uploads-ssl.webflow.com/60f35132d5febb6352ec9d81/62e15badd261e9145b255187_What%27s%20New%20July.png        4936w
                " className="cover-image" />
            <div className="html-embed w-embed w-script">
                <script type="text/javascript" src="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-6228c0d7575b9937"></script>
                <div className="addthis_inline_share_toolbox_bf8p"></div>
            </div>
            <div className="blog-post-content w-richtext">
                <p>
                At Chefsknock, we promise to go the extra mile and provide more, every time. We always go above and beyond what our clients, customers and partners expect from us. Want to find out what Chef’s Knock can offer you? Connect with us today to understand how our solutions can accommodate your unique needs.
                </p>
                <h2>Zero-Charge</h2>
                <p>
                At Chefsknock, we believe that everyone should have access to top-quality culinary professionals without breaking the bank. 
                </p>
                <p>
                That's why we offer our chef hiring services at zero charge to our clients. 
                </p>
                <p>
                With us, you can enjoy delicious, restaurant-quality meals in the comfort of your own home, without any additional fees.
                </p>
                <h2>Features that set us apart:</h2>
                <ul>
                <li>India’s first Online Service that provides Cooks as per your Cuisine at your Doorstep.</li>
                <li>Restaurant like Experience for your Private place and Variety of Cuisines to Choose From.</li>
                <li>Dedicated Team Working for your Event and all this Convenience is just a Click Away.</li>
                </ul>
                <h2>Our Quatity</h2>
                <p>In our pursuit to provide unique range of delicacies,
                we constantly monitor food trends and keep updating our menu with scrumptious new items.
                Our meals are prepared by seasoned chefs and served by a passionate staff trained thoroughly in food and hospitality management.
                We also conduct periodic inspections and training sessions to ensure that all quality protocols are religiously practiced.<br/><br/>
                We specialize in understanding the specific needs of our clients and provide customized food solutions tailored specifically to their requirements and taste.
                Our <b>client-centric</b> approach coupled with premium food quality and industry leading service have earned us the valuable trust of our clients.
                We constantly strive to improvise our methods and update our know-how to better serve our clients.<br/><br/>
                Quality is the framework of our thriving service. We are committed to provide exceptional quality of food and services by constantly implementing new procedures that will help us prepare clean and high quality meals. Our quality policy is a binding factor for all members of our team and is inherent in all our services.
                </p>
                <h3>Safety and Hygiene</h3>
                <ul className="text-left">
                <div className="pt-3" data-animate="fadeInDown">
                <li>We solemnly guarantee against all food malpractices, food adulteration and assure a completely <b>hygienic and healthy food</b> preparation.</li>
                <li>We assure use of hygiene equipment such as <b>Hair Nets, Food Safety Gloves and Sanitized Apparel</b> in all food preparation areas.</li>
                <li>We strictly abide by all health protocols and conduct inspections to ensure their rigorous implementation.</li>
                <li>We strive to exceed customer expectations by providing exceptional food <b>quality and taste.</b></li>
                <li>We aim to constantly improve our <b>services</b> by actively implementing customer feedback.</li>
                </div>
                </ul>
                <h2>Our Values</h2>
                <h3>Quality</h3>
                <p>
                We strive for excellence in all that we do. We set high personal and company standards,
                and then consistently try to exceed them.
                We attract self-motivated, highly capable, results-oriented people and invest heavily in their development.
                </p>
                <h3>Openness &amp; Honesty</h3>
                <p>
                We believe that open communication builds trust, which is the foundation of any relationship whether
                    it’s with our employees or our clients.
                </p>
                <h3>Teamwork</h3>
                <p>
                Our power comes from the talented people within our company. We create an environment
                    in which every person is a valued and respected member, encouraged to contribute and share, and recognized and rewarded for their efforts.
                </p>
                <h3>Flexibility</h3>
                <p>
                We believe that flexibility leads to excellence and we tailor all our services to the specific needs of each client.
                </p>
                <h3>Passion</h3>
                <p>
                We are passionate and committed in heart and mind to what we do. Our employees work around the clock coming up with ideas.
                </p>
                <h3>Integrity</h3>
                <p>
                When we face a tough decision we never compromise our values and principles. We do what is right, not what is the easiest.
                </p>
                {/* <h2>Test</h2>
                <h3>Embeds</h3>
                <p>
                Have an existing website or portfolio that showcases your products?
                Then you’re going to love this new feature: embeds!
                </p>
                <p>
                Now, you can easily embed your Chefsknock store on other platforms via
                HTML. We know how clunky and complex other online order-taking
                platforms can be, so now you don’t have to choose.
                </p>
                <p>
                Take orders right from your current site by embedding your Chefsknock
                shop.
                </p>
                <p>Here’s how to set it up:</p>
                <figure className="w-richtext-figure-type-image w-richtext-align-fullwidth" style="max-width: 1600pxpx"
                className="w-richtext-align-fullwidth w-richtext-figure-type-image">
                <div>
                    <img
                    src="https://uploads-ssl.webflow.com/60f35132d5febb6352ec9d81/62e156e95cb08fa0d24c5016_Icb89ROtb35QO_V-_uJrXL1QSUC2cqQNdsEdGeiHLvMBWqvwHkbrbZD8dy3FGT1J9QA7VUAIss5TXcSdHpDuZPU5fitD4Y5of6o-g7jREytSz2Cmj7aOg4bwTUr8jyzreeeowX-3cGOqdwzCMH8.png"
                    alt="" />
                </div>
                </figure> */}
                {/* <p>
                1. Go to your Chefsknock dashboard and navigate to
                <strong>Share.</strong>
                </p>
                <p>2. Scroll down to <strong>Embed in a web page.</strong></p>
                <p>
                3. Select the size you want your embed to be in desktop and mobile.
                </p>
                <figure className="w-richtext-figure-type-image w-richtext-align-fullwidth" style="max-width: 1600pxpx"
                className="w-richtext-align-fullwidth w-richtext-figure-type-image">
                <div>
                    <img
                    src="https://uploads-ssl.webflow.com/60f35132d5febb6352ec9d81/62e156ea2047601ce81b9e54_V8P8dlt9-yZJ2zdOw0JQhiwnoRbx7YZ4BN6e6WjEg_6hWkUahc9s-OC0w1banAqvfgw6089QNGc6mRCVgon7yZfqQCrg1dS57UPkgNCzu2t8lp5XVskqZ9RBK3Qwp5rhvtUBNgTZ4iQLV9IrERw.png"
                    alt="" />
                </div>
                </figure>
                <p>4. Get the code.</p>
                <p>
                5. Copy the code and paste it in your website’s HTML where you want
                the embed to appear.
                </p>
                <p>
                And <em>voila</em>! You’re all set to make and manage sales using
                the simplest, most beautiful order-taking platform around (if we do
                say so ourselves!).
                </p> */}
                {/* <h3>Verified badges</h3>
                <p>
                Customers love to buy from brands they trust. It’s why they read
                reviews online before making a purchase!
                </p>
                <p>
                But here’s the thing—gathering good reviews takes time and you want
                to ramp up your trustworthiness in order to make sales online
                quickly. Our Verified badges can help you with that.
                </p>
                <p>
                Verified badges are awarded to trustworthy merchants who have proven
                that they deliver quality goods and services.
                </p>
                <p>Here’s how you can earn a verified badge:</p>
                <figure className="w-richtext-figure-type-image w-richtext-align-fullwidth" style="max-width: 1600pxpx"
                className="w-richtext-align-fullwidth w-richtext-figure-type-image">
                <div>
                    <img
                    src="https://uploads-ssl.webflow.com/60f35132d5febb6352ec9d81/62e156e95ed98b8e4393f62d_PdYWTL0exWguMB4wk9Sn4G3ETcZyEfn-lGU3vROAlY5AmDHhGC6_Q0dvGselbd0mA_O1JgkUOqom-aetJk1HZdLSCB1b31vRwEVylle04FqJvoB6JvHt4y8Khuxxn5MJ5T1MHFQ0_GGE3TgjcFE.png"
                    alt="" />
                </div>
                </figure>
                <p>1. Verify your email address.</p>
                <p>2. Subscribe to a premium plan.</p>
                <p>3. Fulfill at least 10 orders through debit/credit card.</p>
                <p>
                A Verified badge in the form of a blue tick will automatically
                appear on your Chefsknock storefront like so:
                </p>
                <figure className="w-richtext-figure-type-image w-richtext-align-fullwidth" style="max-width: 1600pxpx"
                className="w-richtext-align-fullwidth w-richtext-figure-type-image">
                <div>
                    <img
                    src="https://uploads-ssl.webflow.com/60f35132d5febb6352ec9d81/62e156eace40a898e7f20099_J5pJ8Bp8iHrjnqcsrh6ZrC5X1AoKs3nGIdBnL-AD2YSnD0l5efedZlrVk7qbhbiwJ7LIqPm48r01Cxr638Y4Q7bkN5ZxgzQPDYFpAsLJk5JhR1AwjrpVPF2N9vtXUSDXv_KThOuegQ4S-yOkLJQ.png"
                    alt="" />
                </div>
                </figure>
                <h3>Enable tipping at checkout</h3>
                <p>
                Yup, you heard that right. Now, you can earn a little extra by
                enabling tips on your Chefsknock store! 
                </p>
                <p>
                Give customers the option to tip you at your online store with just
                a few simple steps.
                </p>
                <p>Here’s how you can set it up:</p>
                <p>
                1. Go to your Chefsknock dashboard and click on
                <strong>Settings</strong>.
                </p>
                <p>
                2. Navigate to <strong>Payments</strong> and scroll down to
                <strong>Extras</strong>.
                </p>
                <p>
                3. Toggle <strong>Tips</strong> and customize your tipping option.
                </p>
                <p>4. Include as many tipping options as you like!</p>
                <figure className="w-richtext-figure-type-image w-richtext-align-fullwidth" style="max-width: 1600pxpx"
                className="w-richtext-align-fullwidth w-richtext-figure-type-image">
                <div>
                    <img
                    src="https://uploads-ssl.webflow.com/60f35132d5febb6352ec9d81/62e156ebea16e15c938240bd_HZaLbpRRPq8XPdzQfZz-4Yo6p1_-LhfzAY1PYo-X9WU8RehycHf_NN-vBPQY8yyk7Z4GYNIw0FffHK0dZW5JTrUxcHYoJ_hv0fJbFowUakwdXncTvK2mAn5ZJ_q9mgVkcgsxSv-F6XX5XANcKwk.png"
                    alt="" />
                </div>
                </figure>
                <p>
                Tips are then calculated based on the percentage of your customers’
                order value. They’re calculated before promo codes and discounts.
                </p>
                <h3>Order notifications </h3>
                <p>
                Afraid you might’ve missed an order? We heard you—and we’ve made
                some major improvements to our dashboard to make our Orders page
                even better. 
                </p>
                <p>
                Now every time you get a new order, whether you’re on desktop or
                mobile, it’ll be marked by a handy yellow indicator so you’ll get to
                see all your unfulfilled orders at one glance.
                </p>
                <figure className="w-richtext-figure-type-image w-richtext-align-fullwidth" style="max-width: 1600pxpx"
                className="w-richtext-align-fullwidth w-richtext-figure-type-image">
                <div>
                    <img
                    src="https://uploads-ssl.webflow.com/60f35132d5febb6352ec9d81/62e156eac420ddc655cc9268_qYNHDORZ2k7JlW3c3jGTp1LXSm1zSJeUVc51y29MUBLrLAxfhRmNs4w4zNXTxw98lHJwgmi_GnCqLtfKsIxOVP173p6e1SwY6yVcCOYsbW5yos6f2XQG6KqC7sizMww9N3npLkM9rAOFGPDqRVM.png"
                    alt="" />
                </div>
                </figure>
                <p>
                On top of that, our new order notification banners will keep you
                notified when an order comes in even when you’re not on the Orders
                page.
                </p>
                <figure className="w-richtext-figure-type-image w-richtext-align-fullwidth" style="max-width: 1600pxpx"
                className="w-richtext-align-fullwidth w-richtext-figure-type-image">
                <div>
                    <img
                    src="https://uploads-ssl.webflow.com/60f35132d5febb6352ec9d81/62e156ebe3d6f9884b8857d7_XTAnfamsZtXaQ1DFQze63B961CR0Dgsibi8370HGFZDa6s1sci6lpT76VzJ7ht34kPrxcTU43ZD73lDwFqvy81c7Uqb5nFcN3w2ImN2Z1msSTty7Y-jBuH3PcOez2he2Jq-P0ygX3irHcXR3vLY.png"
                    alt="" />
                </div>
                </figure>
                <h3>Organize order summary by time slot</h3>
                <p>
                Have trouble deciding which orders you should get to first? Order
                summaries can now be sorted by time slot. 
                </p>
                <p>
                No more looking through your orders and reorganizing them in a
                separate excel sheet. Now, you’ll get to know which orders you
                should fulfill first based on how time sensitive they are!
                </p>
                <figure className="w-richtext-figure-type-image w-richtext-align-fullwidth" style="max-width: 1600pxpx"
                className="w-richtext-align-fullwidth w-richtext-figure-type-image">
                <div>
                    <img
                    src="https://uploads-ssl.webflow.com/60f35132d5febb6352ec9d81/62e156eadca3e3595cbb6636_aSZgVL0BvHn6GWLV1lKm61EyKU6CpeE6xu21_mEE83DKZaLWH2aSZzw1Rt7-fUIC-j7xwXbfdk0WfJy6nKZ1qdN5c9c0jxwrPpm86XXTTOzE704QdtbW6aslsnCJznYNQxjPearhzbAtTbgTjYwEPnA.jpeg"
                    alt="" />
                </div>
                </figure>
                <h3>Add lead time by time slots (for Pickup)</h3>
                <p>
                Packing for deliveries and pickups take a bit of coordination and
                we’re here to help with that. 
                </p>
                <p>
                Now, you can set a lead time to help you with order management when
                customers come for pickups so you don’t get overwhelmed.
                </p>
                <p>Here’s how to set up lead time in your Chefsknock dashboard:</p>
                <p>
                1. Go to your dashboard and navigate to <strong>Settings</strong>.
                </p>
                <p>2. Navigate to <strong>Fulfillment</strong>.</p>
                <p>3. Scroll down to <strong>Pickup</strong>.</p>
                <figure className="w-richtext-figure-type-image w-richtext-align-fullwidth" style="max-width: 1600pxpx"
                className="w-richtext-align-fullwidth w-richtext-figure-type-image">
                <div>
                    <img
                    src="https://uploads-ssl.webflow.com/60f35132d5febb6352ec9d81/62e156eba644c370543f63e9_TKVyCvLMFsRT-8-lIDbbufOG9W5Pdc9TVUKifw2Ut1kTvur3f0i52A-0rqiTVP0fOy720ToFiLGxq9XbXu_giuXm3Wjld4-1qp6aqOK1Q30Tex8LS-NgISCl25DxrzSWqpLndKlWtKLvwX_3TMo.png"
                    alt="" />
                </div>
                </figure>
                <h3>“Everything Else” for uncategorized products</h3>
                <p>
                Having product categories help customers navigate through your store
                easily. It improves customer experience and helps with conversion
                rates because they can easily find what they’re looking for.
                </p>
                <p>
                But we also know that sometimes you miss adding a category to a
                product…and that it might end up missing from your shop. (Oops!)
                </p>
                <p>
                To make sure this doesn’t happen, we’ve made a new improvement to
                our product. So if you haven’t had the time to categorize all your
                products, don’t sweat! We’ve created an “Everything Else” category
                for all your uncategorized products.
                </p>
                <p>
                This makes sure that every product you list on your shop will be
                shown to customers. And if you happen to find these products in
                Everything Else, that’ll be your little reminder to add it to the
                proper category. (Or, you know, keep them there—your business, your
                call!)
                </p>
                <p>Here’s how it looks like on your storefront:</p>
                <figure className="w-richtext-figure-type-image w-richtext-align-fullwidth" style="max-width: 1600pxpx"
                className="w-richtext-align-fullwidth w-richtext-figure-type-image">
                <div>
                    <img
                    src="https://uploads-ssl.webflow.com/60f35132d5febb6352ec9d81/62e156eb26fa5b39a3dc0f23_ewaI8XSeCNW1RCHH5Qh3g6_7xBAzvIT97oyhNGkBFEBJhP8h7o984VzeaKq-XgIOFA80tRLbVC6qb-jYKXMxzGLAGh6b4qZAzckuZAqRtGkVS3l0X5hrpHmFg4Vng0-wkSLcRdLTTPBi_NzmzHLIWBA.jpeg"
                    alt="" />
                </div>
                </figure>
                <p>And here’s what it’ll look like on your live shop:</p>
                <figure className="w-richtext-figure-type-image w-richtext-align-fullwidth" style="max-width: 1600pxpx"
                className="w-richtext-align-fullwidth w-richtext-figure-type-image">
                <div>
                    <img
                    src="https://uploads-ssl.webflow.com/60f35132d5febb6352ec9d81/62e156eb5ed98b7da793f78a_BTZtsXF5xNL1rfwGpILUJW95JzZES8-sO4TX2ZMizvWXMaONCNUL3NwjFSTi1tqoO9FodfwPvAvnp_vNgZZZY6RYIyrsBquwRTTnOc3DTgxRDx9ItM6HjxjmgOo4NFbJTeL__-CrOTLEcWsyCOkbwdM.jpeg"
                    alt="" />
                </div>
                </figure>
                <h2>Try it today: A better mobile experience awaits!</h2>
                <p>
                We’re making
                <a
                    href="https://support.Chefsknock.co/en/articles/6404839-introducing-orders-2-0-our-new-orders-page-on-mobile">order
                    management on mobile better with Orders 2.0.</a>
                Can’t wait to try it? Opt in today to experience smoother, more
                streamlined order taking.
                </p>
                <figure className="w-richtext-figure-type-image w-richtext-align-fullwidth" style="max-width: 1600pxpx"
                className="w-richtext-align-fullwidth w-richtext-figure-type-image">
                <div>
                    <img
                    src="https://uploads-ssl.webflow.com/60f35132d5febb6352ec9d81/62e156ec2047607a451ba024_rXcCelo3J3N7d6h8CumDlbBki4ePv8YszLxaGLahK9tqnoIWwmpmVcXTCQ29RvJuxI0cwDWHK4KY6fZbGgZXpj59Bm_JH1HBJVMIu9NLFsPyf-J9LpzLh-m5Je177RUKroS91nS0Fxxhh0RZjZYWxFM.png"
                    alt="" />
                </div>
                </figure>
                <h2>Keep tabs on Chefsknock</h2>
                <p>
                Can’t get enough? We can’t either. And while we’re going to make it
                a point to send you roundups of all our hottest new improvements
                each month, here are some awesome posts to keep you company in the
                meantime. Til next month!
                </p>
                <h3>Your Success is Our Success</h3>
                <p>
                ‍<a href="https://www.Chefsknock.co/">Chefsknock</a> is proud to be a
                company that puts local businesses first. After all, you’re the
                reason we’re here. We&#x27;re on a mission to transform independent
                businesses to accept online orders. If you are ready to  sign up for
                a free trial simply register your store
                <a href="http://Chefsknock.co/create">here</a>, it only takes a couple
                of minutes.
                </p>
                <ul role="list">
                <li>
                    <a href="https://www.Chefsknock.co/resources/Chefsknock-remote-startup-morale-productivity">4 Ways Chefsknock
                    Builds Morale and Productivity</a>
                </li>
                <li>
                    <a href="https://www.Chefsknock.co/resources/Chefsknock-culture-iceland-retreat-2022">Team Chefsknock Goes to
                    Iceland—Our Culture of Playing to Win</a>
                </li>
                <li>
                    <a href="https://www.Chefsknock.co/resources/digital-nomad-tips-remote-software-engineer">Yes, You Can Have a
                    Full-Time Job and Travel the World</a>
                </li>
                </ul>
                <p>
                <a href="https://Chefsknock.notion.site/Chefsknock-is-hiring-a4c6094b18ff4db49f24aa3d21945adf">P.S. We’re
                    hiring!</a>
                </p> */}
            </div>
            </div>
        </div>
        <FooterCard />
    </>
  )
}

export default WhyUs
