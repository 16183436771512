import React from 'react'
import Accordion from '../../Components/Accordion/Accordion';

const Privacy = () => {
    const header = {
        title: 'Privacy Policy',
        subTitle: `Welcome to Chefsknock's privacy policy ("Privacy Policy" or "Policy").`
    }
    const data = [
        {
          question: "Introduction",
          answer: `Chefsknock Hospitality Private Limited and its affiliates (collectively, "Chefsknock", "we" or "us") are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines our practices regarding the collection, storage, usage, processing, and disclosure of personal data that you consent to share with us when you access, use, or interact with our mobile applications "Chefsknock" ("App").`
        },
        {
          question: "Objective and Background",
          answer: "The purpose of this Privacy Policy is to maintain the privacy and protect the personal information of our users, customers, and partners and to comply with applicable laws and regulations. This Policy explains how Chefsknock collects, uses, and safeguards your personal data."
        },
        {
          question: "Scope",
          answer: "This Policy applies to all individuals who use the Chefsknock mobile application and the services provided through the App. It covers the collection of personal data and usage data that you provide voluntarily or automatically when using the App.",
        },
        {
          question: "What We Collect",
          answer: "We may collect the following types of information from you:",
          ul: [
            "<b>Contact Data:</b> Your name, email address, phone number, and location data.",
            "<b>User Profile:</b> Information you choose to share on the App, such as photos, comments, events, or other user-generated content.",
            "<b>Device Information:</b> Information about your device, including device type, operating system, unique identifiers, and mobile network information.",
            "<b>Usage Data:</b> Information on how you use and interact with the App, such as features used, pages visited, and actions taken.",
            "<b>Transaction Data:</b> Details of the services or Chef Services you have availed/provided, limited payment details for transaction tracking, and UPI IDs for processing payments.",
            "<b>Food and Beverage Preferences:</b> Data about your food and beverage preferences and consumptions, including stocked food, preferred brands, and types of food consumed.",
            "<b>Other Data:</b> Information you may provide for account validation, customer support, reviews, and more."
          ]
        },
        {
          question: "How We Use Your Information",
          answer: "We use your personal data for the following purposes:",
          ul: [
            "<b>Providing Services:</b> To offer you access to and use of our App and the Chef Services.",
            "<b>Communication:</b> To contact you regarding updates, promotions, and important information related to the App and our services.",
            "<b>Analytics:</b> To analyze user behavior, monitor trends, and improve the App's functionality.",
            "<b>Personalization:</b> To customize your experience and tailor recommendations based on your preferences.",
            "<b>Service Improvement:</b> To enhance the performance of the App and optimize delivery routes for Chef Services.",
            "<b>Marketing:</b> To send you promotional emails and offers based on your interests (you can opt-out anytime).",
            "<b>Compliance:</b> To comply with legal obligations and respond to court orders or government requests."
          ]
        },
        {
          question: "Data Sharing and Disclosure",
          answer: "We may share your personal data with:",
          ul: [
            "<b>Third-Party Service Providers:</b> We share data with service providers assisting in App operations, such as mapping and routing services.",
            "<b>Legal Requirements:</b> We may disclose information to comply with laws, regulations, or legal requests.",
            "<b>Business Partners:</b> Data may be shared with affiliates and partners to improve our services and offer personalized experiences.",
            "<b>Aggregated Data:</b> Non-personal data may be shared for analytical research and trend analysis."
          ]
        },
        {
          question: "Data Security",
          answer: "We employ industry-standard security measures to protect your personal data from unauthorized access, alteration, or disclosure. However, no method of data transmission is entirely secure, and we cannot guarantee absolute security."
        },
        {
          question: "User Control and Opt-Out",
          answer: "You can control cookies and tracking features on your device to customize your privacy settings. You may opt-out of receiving promotional communications from us at any time."
        },
        {
          question: "Changes to the Privacy Policy",
          answer: "This Privacy Policy may be updated periodically. By continuing to use the App after changes, you agree to the revised Policy. <br/><br/> For any questions or concerns regarding this Privacy Policy or your personal data, please contact us at contact@chefsknock.com. <br/><br/> By using the Chefsknock App, you consent to the practices outlined in this Privacy Policy. Please review this Policy carefully, and if you do not agree with any part of it, please refrain from using the App.",
        },
        {
          question: "Your Rights",
          answer: "",
          ul: [
            `<b>Access and Correction:</b> You have the right to update or correct your information provided to us. We will make best efforts to provide you with the ability to access and correct inaccurate or deficient data, subject to legal requirements.`,
            `<b>Opting-out of Marketing Communications:</b> You have the right to opt-out of receiving marketing and promotional communications from us by following the instructions provided in such communications.`
          ]
        },
        {
          question: "Obligation",
          answer: "You agree not to use our App to host, display, upload, modify, publish, transmit, store, update, or share any information that violates any laws, infringes on proprietary rights, or is harmful, defamatory, obscene, or harassing.",
        },
        {
          question: "Deletion of account",
          answer: "You may delete your Chefsknock account and personal data by contacting us at <b>contact@chefsknock.com</b>. We may take up to 30 (thirty) working days to process your request.",
        },
        {
          question: "Links to other website",
          answer: "Our App may contain links to other websites. We are not responsible for the protection and privacy of any information you provide while visiting such sites.",
        },
        {
          question: "Contacting Us",
          answer: "If you have any questions or concerns regarding this Privacy Policy, you may contact us at <b>contact@chefsknock.com</b>"
        },
        {
          question: "Privacy Policy Changes",
          answer: "We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any significant changes by posting the revised Privacy Policy on our App."
        }
    ];

    return (
        <Accordion header={header} data={data} />
    )
}

export default Privacy
