import { Link } from "react-router-dom"
import "./ComingSoon.css"

const ComingSoon = () => {
  return (
    <div className="coming-soon">
        <div className="coming-soon-wrapper">
            <h1 className="coming-soon-title">We are not in your city<span className="dot">.</span></h1>
            <p className="coming-soon-subtitle">We are coming soon in your city.</p>
            <div className="coming-soon-icons">
                <Link to="https://twitter.com/chefknock/" target="_blank"><i className="fa fa-twitter"></i></Link>
                <Link to="https://www.facebook.com/chefsknock/" target="_blank"><i className="fa fa-facebook"></i></Link>
                <Link to="https://www.instagram.com/chefsknock" target="_blank"><i className="fa fa-instagram"></i></Link>
            </div>
        </div>
    </div>
  )
}

export default ComingSoon
