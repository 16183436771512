import './Nav.css'
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Nav = () => {
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  const defaultNav = ['/'];
  const header = !defaultNav.includes(location.pathname);

  return (
    <div
      data-animation="default"
      className="navbar w-nav"
      data-easing2="ease"
      data-easing="ease"
      data-collapse="medium"
      data-w-id="33c883c6-4afc-cc73-3bca-d2857a9d4bc2"
      role="banner"
      data-duration="400"
      id="Navigation"
      style={{ backgroundColor: (isMobileMenuOpen || header) ? 'rgba(255, 255, 255, 255)' : '#ff5a5f' }}
    >
      <div className="navigation-container">
        {/* nav for desktop view */}
        <div className="navigation-left">
          <Link to="/" aria-current="page" className="brand w-nav-brand w--current flex-active">
            <img
              event-property-id="12"
              className="image-66"
              src={header ? "assets/images/red_chefsknock_fevicon.png" : "assets/images/white_chefsknock_icon.png"}
              width="210"
              alt=""
              event-tracking="Button Clicked"
              event-property-location="Navigation Bar"
              event-property-action="Go to Home"
              style={{height: "40px", width: "40px"}}
            /> <span className='nav-logo-name' style={{fontSize: "26px"}}>Chefsknock</span>
          </Link>
        </div>
        {/* nav for mobile view */}
        <div className="navigation-right">
          <div className="menu-button w-nav-button" onClick={handleMenuToggle}>
            <div className="icon w-icon-nav-menu"></div>
          </div>
          <div className="log-in-container log-in-tablet">
            <Link
              to="https://play.google.com/store/apps/details?id=com.developergulam.ck_partner&pcampaignid=web_share"
              target="_blank"
              className="log-in w-button">
              <div className='inner-btn-section'>
                <img
                  className="footer-icon"
                  src="assets/icons/google-play.png"
                  alt="Play Store"
                  style={{height: "20px", width: "20px"}}
                />
                Get Partner App
              </div>
            </Link>
          </div>
          <nav role="navigation" className={`nav-menu w-nav-menu ${isMobileMenuOpen ? 'w--nav-menu-open' : ''}`}>
            <Link
              to="/"
              event-property-location="Navigation Bar"
              event-tracking="Element Clicked"
              event-property-id="10"
              event-property-action="Go to Home"
              className="nav-link w-nav-link"
            >
              Home
            </Link>
            <Link
              to="/why-us"
              event-property-id="8"
              event-property-location="Navigation Bar"
              event-property-action="Go to Blog"
              event-tracking="Element Clicked"
              className="nav-link w-nav-link"
            >
              Why Us
            </Link>
            <div className="log-in-container log-in-desktop">
              <Link
                event-property-location="Navigation Bar"
                event-tracking="Element Clicked"
                event-property-id="7"
                event-property-action="Log In"
                to="https://play.google.com/store/apps/details?id=com.developergulam.ck_partner&pcampaignid=web_share"
                target="_blank"
                className="log-in w-button"
              >
                <div className='inner-btn-section'>
                <img
                  className="footer-icon"
                  src="assets/icons/google-play.png"
                  alt="Play Store"
                  style={{height: "25px", width: "25px"}}
                />
                Get Partner App
              </div>
              </Link>
            </div>
          </nav>
        </div>
      </div>
      <div className={`w-nav-overlay ${isMobileMenuOpen ? 'w--open' : ''}`} data-wf-ignore="" id="w-nav-overlay-0" style={{ height: '6001.3px', display: isMobileMenuOpen ? 'block' : 'none' }}>
        <nav role="navigation" className={`nav-menu w-nav-menu ${isMobileMenuOpen ? 'w--nav-menu-open' : ''}`} style={{ transform: 'translateY(0px) translateX(0px)', transition: 'transform 400ms ease 0s' }} data-nav-menu-open="">
          <Link to="/" event-property-location="Navigation Bar" event-tracking="Element Clicked" event-property-id="10" event-property-action="Go to Home" className="nav-link w-nav-link w--nav-link-open">
            Home
          </Link>
          <Link to="/why" event-property-id="8" event-property-location="Navigation Bar" event-property-action="Go to Blog" event-tracking="Element Clicked" className="nav-link w-nav-link w--nav-link-open">
            Why Us
          </Link>
          {/* <Link to={BASE_URL + "/chefs/register"} event-property-action="Go to Jobs" event-tracking="Element Clicked" event-property-location="Navigation Bar" event-property-id="9" target="_blank" className="nav-link hiring w-nav-link w--nav-link-open">
            Chefs Registration!
          </Link> */}
          <Link 
            to="https://play.google.com/store/apps/details?id=com.developergulam.ck_partner&pcampaignid=web_share"
            target="_blank"
            className="nav-link log-in-mobile w-nav-link w--nav-link-open"
          >
            <div className='inner-btn-section'>
                <img
                  className="footer-icon"
                  src="assets/icons/google-play.png"
                  alt="Play Store"
                  style={{height: "35px", width: "35px"}}
                />
                Get Partner App
              </div>
          </Link>
          {/* <div className="log-in-container log-in-desktop">
            <Link event-property-location="Navigation Bar" event-tracking="Element Clicked" event-property-id="7" event-property-action="Log In" to="/login" className="log-in w-button">
              LOG IN
            </Link>
          </div> */}
        </nav>
      </div>
    </div>
  );
};

export default Nav;
