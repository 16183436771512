import React from "react";

const Brand = () => {
  const brands = [
    {
      name: "Mother Dough",
      url: "https://www.instagram.com/motherdoughbakery/",
      logoSrc: "https://uploads-ssl.webflow.com/60f35132d5febb6f03ec9d7a/60f981113043e10d9dd5688f_motherdough2.png",
      logoAlt: "Mother Dough Logo",
      className: "logo-mother-dough"
    },
    {
      name: "The Baked Area",
      url: "https://www.instagram.com/thebakedarea/",
      logoSrc: "https://uploads-ssl.webflow.com/60f35132d5febb6f03ec9d7a/60f96de6e32d78b0df34e5f8_bakedarea.png",
      logoSrcset: "https://uploads-ssl.webflow.com/60f35132d5febb6f03ec9d7a/60f96de6e32d78b0df34e5f8_bakedarea-p-500.png 500w, https://uploads-ssl.webflow.com/60f35132d5febb6f03ec9d7a/60f96de6e32d78b0df34e5f8_bakedarea.png 742w",
      logoSizes: "(max-width: 479px) 100vw, (max-width: 991px) 94vw, 741.99658203125px",
      logoAlt: "The Baked Area Logo",
      className: "logo-baked-area"
    },
    {
      name: "Mochata",
      url: "https://www.instagram.com/mochata_/",
      logoSrc: "https://uploads-ssl.webflow.com/60f35132d5febb6f03ec9d7a/60f9816cb2b6567ae8bb7554_mochata2.png",
      logoAlt: "Mochata Logo",
      className: "logo-mochata"
    },
    {
      name: "Theobroma Bake Lab",
      url: "https://www.instagram.com/theobroma.bakelab/",
      logoSrc: "https://uploads-ssl.webflow.com/60f35132d5febb6f03ec9d7a/60f981bb759ef7529f0664b4_theobroma2.png",
      logoAlt: "Theobroma Bake Lab Logo",
      className: "logo-theobroma"
    },
    {
      name: "Indocin Food",
      url: "https://www.instagram.com/indocinfood/",
      logoSrc: "https://uploads-ssl.webflow.com/60f35132d5febb6f03ec9d7a/60f9692ed3d37ea6454e17d6_indocin.png",
      logoAlt: "Indocin Food Logo",
      className: "logo-indocin"
    },
    {
      name: "Valv Beaute Singapore",
      url: "https://www.instagram.com/vavlbeautesingapore/",
      logoSrc: "https://uploads-ssl.webflow.com/60f35132d5febb6f03ec9d7a/60f97dc12f5c1985eb2f1984_valv.png",
      logoAlt: "Valv Beaute Singapore Logo",
      className: "logo-valv"
    },
    {
      name: "Thor Storage",
      url: "https://www.instagram.com/thor.storage/",
      logoSrc: "https://uploads-ssl.webflow.com/60f35132d5febb6f03ec9d7a/60f97999a97a7c153271b83f_thor2.png",
      logoAlt: "Thor Storage Logo",
      className: "logo-thor"
    },
    {
      name: "Y Combinator",
      url: "https://www.ycombinator.com/",
      logoSrc: "https://uploads-ssl.webflow.com/60f35132d5febb6f03ec9d7a/60f98240994bfcc022c6b611_yc.png",
      logoAlt: "Y Combinator Logo",
      className: "logo-puffs"
    }
  ];

  return (
    <div class="content-section">
        <div className="container">
            <div className="title-wrap-centre">
                <h2 className="features-title">Our Trusted Brands</h2>
            </div>
            <div className="w-layout-grid grid-4">
                {brands.map((brand, index) => (
                <a
                    key={index}
                    href={brand.url}
                    target="_blank"
                    rel="noreferrer"
                    className="w-inline-block"
                >
                    <img
                    src={brand.logoSrc}
                    loading="lazy"
                    srcset={brand.logoSrcset}
                    sizes={brand.logoSizes}
                    alt={brand.logoAlt}
                    className={`logos ${brand.className}`}
                    />
                </a>
                ))}
            </div>
        </div>
    </div>
  );
};

export default Brand;
