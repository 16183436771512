import React from 'react';
import './Footer.css'
import { Link } from 'react-router-dom';
import { getBaseURL } from '../../Utils/PathUtils';
import Swal from 'sweetalert2';

const Footer = () => {
  const BASE_URL = getBaseURL();

  const handleClick = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const showComingSoonPopup = () => {
    Swal.fire({
      title: 'Coming Soon',
      text: 'Wr are launching our application soon.',
      showConfirmButton: false,
    });
  }

  return (
    <div className="footer">
      <div className="container">
        <div className="w-layout-grid footer-grid">
          <div id="w-node-_67f7db32-28d2-9f37-8b84-a159dcc6da5a-dcc6da57" className="footer-column">
            <div className='flex-active'>
              <img
                src="assets/images/white_chefsknock_icon.png"
                height="40"
                alt=""
                className="footer-logo"
                style={{height: "50px", width: "50px"}}
              /> <span className='nav-logo-name' style={{fontSize: "29px"}}>Chefsknock</span>
            </div>
            <p className="paragraph-home paragraph-footer">
              Find verified and experienced chefs easily.
            </p>
          </div>
          <div id="w-node-_67f7db32-28d2-9f37-8b84-a159dcc6da5e-dcc6da57" className="footer-column">
            <div className="title">Product</div>
            <Link to="/" className="footer-link" onClick={handleClick}>
              Home
            </Link>
            <Link to="/why-us" className="footer-link" onClick={handleClick}>
              Why Us
            </Link>
            {/* <Link to="/pricing" className="footer-link" onClick={handleClick}>
              Pricing
            </Link> */}
            <Link
              to="mailto:contact@chefsknock.com"
              className="footer-link"
              onClick={handleClick}
            >
              Contact
            </Link>
            {/* <Link
              to="https://www.chefsknock.com/partners"
              target="_blank"
              className="footer-link"
              onClick={handleClick}
            >
              Deals
            </Link> */}
          </div>
          <div id="w-node-_67f7db32-28d2-9f37-8b84-a159dcc6da69-dcc6da57" className="footer-column">
            <div className="title">Resources</div>
            {/* <Link
              to="https://www.chefsknock.com/resources"
              className="footer-link"
              onClick={handleClick}
            >
              Blog
            </Link> */}
            <Link
              to="https://www.instagram.com/chefsknock/"
              target="_blank"
              className="footer-link"
              onClick={handleClick}
            >
              Instagram
            </Link>
            <Link
              to="https://www.facebook.com/chefsknock/"
              target="_blank"
              className="footer-link"
              onClick={handleClick}
            >
              Facebook
            </Link>
            <Link
              to="https://twitter.com/chefknock"
              target="_blank"
              className="footer-link"
              onClick={handleClick}
            >
              Twitter
            </Link>
            {/* <Link
              to="https://support.cococart.co/"
              target="_blank"
              className="footer-link"
              onClick={handleClick}
            >
              Help Center
            </Link> */}
          </div>
          {/* <div id="w-node-bf0e88b5-5151-a0f5-5a5f-683bc15c39ba-dcc6da57" className="footer-column">
            <div className="title">Sell Anything</div>
            <Link
              to="http://www.chefsknock.com/sell-crafts-online-easy"
              target="_blank"
              className="footer-link"
              onClick={handleClick}
            >
              Crafts
            </Link>
            <Link
              to="http://www.chefsknock.com/sell-merch-online-free-website"
              target="_blank"
              className="footer-link"
              onClick={handleClick}
            >
              Merchandise
            </Link>
            <Link to="http://www.chefsknock.com/sell-food-online-easy" className="footer-link" onClick={handleClick}>
              Food and Drink
            </Link>
          </div> */}
          <div id="w-node-_67f7db32-28d2-9f37-8b84-a159dcc6da72-dcc6da57" className="footer-column">
            <div className="title">Company</div>
            <Link
              to={BASE_URL + "/chefs/register"}
              target="_blank"
              className="footer-link"
              onClick={handleClick}
            >
              We're Hiring
            </Link>
            <Link to="/terms" className="footer-link" onClick={handleClick}>
              Terms
            </Link>
            <Link to="/privacy-policy" className="footer-link" onClick={handleClick}>
              Privacy
            </Link>
          </div>
        </div>
        
        <div className='download-app-section'>
          <Link onClick={showComingSoonPopup}>
            <img 
              src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png" 
              width="150" 
              height="60" 
              alt="Get it on Google Play" 
              border="0"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;