import React, { useState } from 'react'
import './Accordion.css'

const Accordion = ({header, data}) => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const toggleAccordion = (index) => {
    if (index === expandedIndex) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };

  return (
    <>
      <div className="content-section faqs-title">
        <div className="container">
          <div className="title-wrap-centre">
            <h2 className="heading">{header.title}</h2>
            {header.subTitle ? (<div class="legal-subtitle">{header.subTitle}</div>) : ''}
          </div>
        </div>
      </div>
      <div className="content-section-short faqs">
        <div className="faqs-container">
          {data.map((obj, index) => (
            <div className="faq-question-wrap" key={index}>
              <p
                href="#"
                className="faq-question-bar w-inline-block"
                onClick={() => toggleAccordion(index)}
              >
                <div className="question-title">{obj.question}</div>
                <img
                  src="https://uploads-ssl.webflow.com/60f35132d5febb6f03ec9d7a/60f9d8d85891d4814cda8ba4_Plus-black.svg"
                  alt=""
                  className="plus"
                  style={{
                    opacity: expandedIndex === index ? '1' : '0.5',
                    transform: expandedIndex === index ? 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(45deg) skew(0deg, 0deg)' : 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                    transformStyle: 'preserve-3d'
                  }}
                />
              </p>
              <div className="faq-content" style={{ display: expandedIndex === index ? 'block' : 'none' }}>
                <p className="faq-paragraph" dangerouslySetInnerHTML={{ __html: obj.answer }} />
                {obj.ul ? (
                  <ul>
                    {obj.ul.map((li, i) => (
                      <li dangerouslySetInnerHTML={{ __html: li }} />
                    ))}
                  </ul>
                ): ''}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default Accordion
