import React from "react";

const HowWork = () => {
  return (
    <div className="content-section steps-container">
      <div className="container">
        <div className="title-wrap-centre">
          <h2 className="heading">How it works</h2>
        </div>
        <div className="w-layout-grid works-grid steps">
          <div className="content-card">
            <div className="steps-icon-wrapper">
              <div className="steps-icon">⚡️</div>
            </div>
            <div className="content-wrapper">
              <h5>Step 1</h5>
              <p className="paragraph-steps">
                Login to your dashboard by just click on login.
              </p>
            </div>
          </div>
          <div className="line-column">
            <div className="line"></div>
          </div>
          <div className="content-card">
            <div className="content-card">
              <div className="steps-icon-wrapper">
                <div className="steps-icon">🖼</div>
              </div>
              <h5>Step 2</h5>
              <p className="paragraph-steps">
                Click on <b>Find Chefs</b> option and filter chefs accordingly.
              </p>
            </div>
          </div>
          <div className="line-column">
            <div className="line"></div>
          </div>
          <div className="content-card">
            <div className="content-card">
              <div className="steps-icon-wrapper">
                <div className="steps-icon">🎉</div>
              </div>
              <h5>Step 3</h5>
              <p className="paragraph-steps">
                Choose your chef and click on <b>hire</b> button to hire a chef.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowWork;
