import './App.css';
import Routers from './Components/Routers';
import Footer from './Components/Footer/Footer';
import Nav from './Components/Navbar/Nav';
import { useLocation } from 'react-router-dom';

function App() {
  const location = useLocation();

  const defaultNav = ['/login', '/*', '/chefs/register'];
  const noDefault = !defaultNav.includes(location.pathname);

  return (
    <div className="App">
      { noDefault ? (<Nav />) : ''}
      <Routers />
      { noDefault ? (<Footer />) : ''}
    </div>
  );
}

export default App;
