import { Route, Routes } from "react-router-dom";
import ComingSoon from "../Pages/ComingSoon/ComingSoon";
import Home from "../Pages/Home/Home";
// import Login from "../Pages/Login/Login";
import Privacy from "../Pages/Privacy/Privacy";
import Terms from "../Pages/Term/Terms";
import WhyUs from "../Pages/WhyUs/WhyUs";

const Routers = () => {
  return (
    <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/why-us" element={<WhyUs />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        {/* <Route path="/login" element={<Login />} /> */}
        {/* <Route path="/register" element={<Signup />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/otp" element={<ConfirmOTP />} />
        <Route path="/account" element={<Account />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/chef-profile" element={<ChefProfile />} />
        <Route path="/about" element={<About />} /> */}
        <Route path="*" element={<ComingSoon />} />
    </Routes>
  )
}

export default Routers
