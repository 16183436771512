import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div id="Header" className="header">
      <div className="container-flex">
        <div className="hero-content">
          <h1 className="hero-h1 heroContentWrap">
            Chefs/Cooks,
            <br />
            now at your
            <br />
            fingertips.
          </h1>
          <p className="hero-paragraph heroContentWrap">
            Hire chefs for your business & Home.
          </p>
          <div className="button-wrapper heroContentWrap">
            <Link
              to="/login"
              event-property-action="Sign Up"
              event-tracking="Element Clicked"
              event-property-id="2"
              event-property-location="Hero"
              className="button w-button"
            >
              Hire Chefs—It&#x27;s Free
            </Link>
          </div>
          <div className="review-wrap heroContentWrap">
            <img
              src="https://uploads-ssl.webflow.com/60f35132d5febb6f03ec9d7a/60f4a3e27df73182b2edecba_noun_stars_1117713-white.png"
              alt=""
              className="review-logo"
            />
            <div className="review-text">
              <span className="brand-span">Loved by 30,000+ chefs</span>
            </div>
          </div>
        </div>
        <div className="hero-image-wrap heroImgWrap">
          <img
            src="./assets/images/mobile_frame.png"
            width="544"
            alt="Preview"
            className="hero-image"
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
