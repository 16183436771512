import React from 'react'
import { Link } from 'react-router-dom'

const FooterCard = () => {
  return (
    <div className="section-cta wf-section">
    <div className="cta-container">
      <h1 className="cta-heading"> Hire chefs easily.</h1>
      <div className="cta-subtitle">
        It&#x27;s free, and takes less than a minute
      </div>
      <div className="button-wrapper cta-button">
        <Link to="/login" event-property-action="Sign Up" event-tracking="Element Clicked"
          event-properties-location="Sign Up CTA" event-property-id="1" className="button cta-button w-button">Hire Now</Link>
      </div>
    </div>
  </div>
  )
}

export default FooterCard
